import React, { useState } from 'react';
import axios from 'axios';

const ClientPanierComponent = () => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [mot_de_passe, setMot_de_passe] = useState('');
  const [adresse, setAdresse] = useState('');
  const [code_postal, setCode_postal] = useState('');
  const [ville, setVille] = useState('');
  const [matricule_fiscal, setMatricule_fiscal] = useState('');
  const [cin, setCin] = useState('');
  const [IDArticle, setIDArticle] = useState('');
  const [Quantite, setQuantite] = useState('');

  const addClientPanier = () => {
    axios
      .post('/api/ClientPanier', {nom, prenom, email, mot_de_passe, adresse, code_postal, ville, matricule_fiscal, cin})
      .then(() => {
        alert('Nouvel item panier ajouté avec succès');
        // Réinitialiser le formulaire si nécessaire
      })
      .catch((error) => {
        console.error('Erreur lors de l\'ajout de l\'item panier:', error);
        // Afficher une alerte ou un message d'erreur ici si nécessaire
      });
  };

  const inputStyle = {
    border: '2px solid black',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '20%',
    height: '30px',
    padding: '12px 20px',
    marginBottom: '40px',
  };

  const ajouterStyle = {
    fontSize: '30px',
    position: 'relative',
    left: '80px',
    margin: '40px auto',
    marginBottom: '20px',
    width: '250px',
    height: '50px',
    borderRadius: '30px',
    border: '2px solid blue',
    color: 'blue',
  };

  const inputContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  };

  return (
    <div>
    <h2 style={{ color: 'blue', fontSize: '40px', marginBottom: '2%', textAlign: 'center' }}>Ajouter un nouveau Client Panier</h2>
      <form onSubmit={addClientPanier} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={nom} onChange={(e) => setNom(e.target.value)} placeholder="Nom" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={prenom} onChange={(e) => setPrenom(e.target.value)} placeholder="Prénom" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="password" value={mot_de_passe} onChange={(e) => setMot_de_passe(e.target.value)} placeholder="Mot de passe" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={adresse} onChange={(e) => setAdresse(e.target.value)} placeholder="Adresse" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={code_postal} onChange={(e) => setCode_postal(e.target.value)} placeholder="Code postal" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={ville} onChange={(e) => setVille(e.target.value)} placeholder="Ville" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={matricule_fiscal} onChange={(e) => setMatricule_fiscal(e.target.value)} placeholder="Matricule fiscal" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={cin} onChange={(e) => setCin(e.target.value)} placeholder="CIN" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={IDArticle} onChange={(e) => setIDArticle(e.target.value)} placeholder="ID_Article" />
        <input style={{ border: '2px solid black', borderRadius: '4px', boxSizing: 'border-box', width: '20%', height: '30px', padding: '12px 20px', marginBottom: '20px' }} type="text" value={Quantite} onChange={(e) => setQuantite(e.target.value)} placeholder="Quantite" />
        <button style={{ fontSize: '30px', position: 'relative', left: '10px', margin: '40px auto', marginBottom: '20px', width: '250px', height: '50px', borderRadius: '30px', border: '2px solid blue', color: 'blue' }} type="submit">Ajouter</button>
      </form>
  </div>
);
};


export default ClientPanierComponent;
