import React from "react";
import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between", // Répartir les éléments à gauche, au centre et à droite
    alignItems: "center",
    height: "100px",
    backgroundColor: "#3b5998", // Couleur de fond de la barre de navigation
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)" // Ombre légère en bas
  };

  const navbarStyle = {
    padding: "10px 0", // Espacement intérieur de la barre de navigation
    flexGrow: 1 // Permet à la barre de navigation de s'étendre et d'occuper tout l'espace disponible
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#fff", // Couleur du texte des liens
    fontWeight: "bold", // Police en gras
    padding: "8px 16px", // Espacement intérieur des liens
    borderRadius: "20px", // Coins arrondis
    transition: "background-color 0.3s" // Transition fluide de la couleur de fond
  };

  const linkHoverStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.1)" // Couleur de fond au survol
  };

  return (
    <>
      <div style={containerStyle} className="centered-container">
        <nav style={navbarStyle} className="navbar">
          <ul style={{ listStyle: "none", display: "flex", justifyContent: "space-between", margin: 0 }}>
            <li>
              <Link
                style={linkStyle}
                to="/Insetcomp"
                activestyle ={linkHoverStyle}
              >
                Ajouter Produit
              </Link>
            </li>
            <li>
              <Link
                style={linkStyle}
                to="/ListProduitComponent"
                activestyle ={linkHoverStyle}
              >
                Liste Des Produit
              </Link>
            </li>
            <li>
              <Link
                style={linkStyle}
                to="/ClientPanier"
                activestyle ={linkHoverStyle}
              >
                Ajouter des Commandes
              </Link>
            </li>
            <li>
              <Link
                style={linkStyle}
                to="/ListClientPanierComponent"
                activestyle ={linkHoverStyle}
              >
                Liste des clients
              </Link>
            </li>
            <li>
              <Link
                style={linkStyle}
                to="/ItemComponent"
                activestyle ={linkHoverStyle}
              >
                image
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <Outlet />
    </>
  );
};

export default Layout;

