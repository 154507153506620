import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ItemComponent = () => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [item, setItem] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    axios.get('/api/item')
      .then(response => {
        setItemsList(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération de la liste des items :', error);
      });
  }, []);

  const handleSelectChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setSelectedItemId(selectedId);
  };

  useEffect(() => {
    if (selectedItemId) {
      axios.get(`/api/item/${selectedItemId}`)
        .then(response => {
          const itemData = response.data;
          itemData.images = JSON.parse(itemData.images);
          itemData.fichier_pdf = JSON.parse(itemData.fichier_pdf);
          setItem(itemData);
          // Mettre la première image en tant qu'image principale par défaut
          if (itemData.images && itemData.images.length > 0) {
            setMainImage(`http://biotika.tofeha.tn/photo/${itemData.images[0]}`);
          } else {
            setMainImage(null);
          }
        })
        .catch(error => {
          console.error('Erreur lors de la récupération de l\'item :', error);
        });
    }
  }, [selectedItemId]);

  const handleThumbnailClick = (imageName) => {
    setMainImage(`http://biotika.tofeha.tn/photo/${imageName}`);
  };

  return (
    <div>
      <h1>Choisir un Item</h1>
      <select onChange={handleSelectChange}>
        <option value="">Sélectionner un Item</option>
        {itemsList.map(item => (
          <option key={item.id} value={item.id}>{item.nom}</option>
        ))}
      </select>

      {item && (
        <div>
          <h2>Détails de l'Item</h2>
          <p>Référence : {item.reference}</p>
          <p>Nom : {item.nom}</p>
          <p>Prix : {item.prix}</p>
          <p>Description : {item.descriptif}</p>
          <p>Promotions : {item.promos_text}</p>
          <p>Résumé : {item.resume}</p>
          <p>Catégorie : {item.category}</p>
          <div>
            <h3>Images de l'Item</h3>
            <div className="image-container">
              {mainImage && <img src={mainImage} alt="Image principale" />}
              <div className="thumbnail-container">
                {item.images && item.images.map((imageName, index) => (
                    <img
  key={index}
  src={`http://biotika.tofeha.tn/photo/${imageName}`}
  alt={`Image ${index + 1}`}
  onClick={() => handleThumbnailClick(imageName)}
  style={{ width: '50px', height: 'auto', marginRight: '5px', cursor: 'pointer' }}
/>

                ))}
              </div>
            </div>
          </div>

          <div className="pdf-container">
            {Array.isArray(item.fichier_pdf) && item.fichier_pdf.map((pdfName, index) => (
              <div key={index}>
                <a href={`http://biotika.tofeha.tn/pdf/${pdfName}`} target="_blank" rel="noopener noreferrer">PDF {index + 1}</a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemComponent;
