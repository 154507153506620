import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Component/Layout';
import NoPage from './Component/NoPage';
import Insetcomp from './Component/Insetcomp';
import ClientPanier from './Component/ClientPanier'; 
import ListProduitComponent from './Component/ListProduitComponent';
import ListClientPanierComponent from './Component/ListClientPanier'
import ItemComponent from './Component/image';

function App() {
  return ( 
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Insetcomp />} />
          <Route path="/Insetcomp" element={<Insetcomp />} />
          <Route path="/ListProduitComponent" element={<ListProduitComponent />} />
          <Route path="/ClientPanier" element={<ClientPanier />} />
          <Route path="/ListClientPanierComponent" element={<ListClientPanierComponent />} />
          <Route path="/ItemComponent" element={<ItemComponent/>} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
