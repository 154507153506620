import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CombinedComponent = () => {
  // State et méthodes pour DesinfestationHygieneComponent
  const [desinfestationHygiene, setDesinfestationHygiene] = useState([]);
  const [newDesinfestationHygiene, setNewDesinfestationHygiene] = useState({
    nom: '',
  });
  const [selectedDesinfestationId, setSelectedDesinfestationId] = useState(null);

  // State et méthodes pour DiagnosticComponent
  const [diagnostics, setDiagnostics] = useState([]);
  const [newDiagnostic, setNewDiagnostic] = useState({
    nom: '',
  });
  const [selectedDiagnosticId, setSelectedDiagnosticId] = useState(null);

  // State et méthodes pour MobilierComponent
  const [mobiliers, setMobiliers] = useState([]);
  const [newMobilier, setNewMobilier] = useState({
    nom: '',
  });
  const [selectedMobilierId, setSelectedMobilierId] = useState(null);

  // State et méthodes pour NouveauteComponent
  const [nouveautes, setNouveautes] = useState([]);
  const [newNouveaute, setNewNouveaute] = useState({
    nom: '',
  });
  const [selectedNouveauteId, setSelectedNouveauteId] = useState(null);

  // State et méthodes pour SoinsPansementsComponent
  const [soinsPansements, setSoinsPansements] = useState([]);
  const [newSoinsPansements, setNewSoinsPansements] = useState({
    nom: '',
  });
  const [selectedSoinId, setSelectedSoinId] = useState(null);

  useEffect(() => {
    loadDesinfestationHygiene();
    loadDiagnostics();
    loadMobiliers();
    loadNouveautes();
    chargerSoinsPansements();
  }, []);

  const loadDesinfestationHygiene = () => {
    axios
      .get('/api/Desinfestationhygiene')
      .then((response) => setDesinfestationHygiene(response.data))
      .catch((error) => console.error('Erreur Desinfestationhygiene:', error));
  };

  const loadDiagnostics = () => {
    axios
      .get('/api/Diagnostic')
      .then((response) => setDiagnostics(response.data))
      .catch((error) => console.error('Erreur Diagnostic:', error));
  };

  const loadMobiliers = () => {
    axios
      .get('/api/Mobilier')
      .then((response) => setMobiliers(response.data))
      .catch((error) => console.error('Erreur Mobilier:', error));
  };

  const loadNouveautes = () => {
    axios
      .get('/api/Nouveaute')
      .then((response) => setNouveautes(response.data))
      .catch((error) => console.error('Erreur Nouveaute:', error));
  };

  const chargerSoinsPansements = () => {
    axios
      .get('/api/SoinsPansement')
      .then((response) => setSoinsPansements(response.data))
      .catch((error) => console.error('Erreur SoinsPansement:', error));
  };

  // Fonctions d'ajout, de mise à jour et de suppression pour DesinfestationHygieneComponent

  const addDesinfestationHygiene = () => {
    axios
      .post('/api/Desinfestationhygiene', newDesinfestationHygiene)
      .then(() => {
        setNewDesinfestationHygiene({ nom: '' });
        console.log('Nouvelle désinfestation/hygiène ajoutée');
        loadDesinfestationHygiene();
      })
      .catch((error) => console.error('Erreur ajout Desinfestationhygiene:', error));
  };

  const deleteDesinfestationHygiene = (id) => {
    axios
      .delete(`/api/Desinfestationhygiene/${id}`)
      .then(() => {
        console.log('Désinfestation/hygiène supprimée');
        loadDesinfestationHygiene();
      })
      .catch((error) => console.error('Erreur suppression Desinfestationhygiene:', error));
  };

  // Fonctions d'ajout, de mise à jour et de suppression pour DiagnosticComponent

  const addDiagnostic = () => {
    axios
      .post('/api/Diagnostic', newDiagnostic)
      .then(() => {
        setNewDiagnostic({ nom: '' });
        console.log('Nouveau diagnostic ajouté');
        loadDiagnostics();
      })
      .catch((error) => console.error('Erreur ajout Diagnostic:', error));
  };

  const deleteDiagnostic = (id) => {
    axios
      .delete(`/api/Diagnostic/${id}`)
      .then(() => {
        console.log('Diagnostic supprimé');
        loadDiagnostics();
      })
      .catch((error) => console.error('Erreur suppression Diagnostic:', error));
  };

  // Fonctions d'ajout, de mise à jour et de suppression pour MobilierComponent

  const addMobilier = () => {
    axios
      .post('/api/Mobilier', newMobilier)
      .then(() => {
        setNewMobilier({ nom: '' });
        console.log('Nouveau mobilier ajouté');
        loadMobiliers();
      })
      .catch((error) => console.error('Erreur ajout Mobilier:', error));
  };

  const deleteMobilier = (id) => {
    axios
      .delete(`/api/Mobilier/${id}`)
      .then(() => {
        console.log('Mobilier supprimé');
        loadMobiliers();
      })
      .catch((error) => console.error('Erreur suppression Mobilier:', error));
  };

  // Fonctions d'ajout, de mise à jour et de suppression pour NouveauteComponent

  const addNouveaute = () => {
    axios
      .post('/api/Nouveaute', newNouveaute)
      .then(() => {
        setNewNouveaute({ nom: '' });
        console.log('Nouvelle nouveauté ajoutée');
        loadNouveautes();
      })
      .catch((error) => console.error('Erreur ajout Nouveaute:', error));
  };

  const deleteNouveaute = (id) => {
    axios
      .delete(`/api/Nouveaute/${id}`)
      .then(() => {
        console.log('Nouveauté supprimée');
        loadNouveautes();
      })
      .catch((error) => console.error('Erreur suppression Nouveaute:', error));
  };

  // Fonctions d'ajout, de mise à jour et de suppression pour SoinsPansementsComponent

  const addSoinsPansements = () => {
    axios
      .post('/api/SoinsPansement', newSoinsPansements)
      .then(() => {
        console.log('Nouveau soin/pansement ajouté');
        chargerSoinsPansements();
      })
      .catch((error) => console.error('Erreur ajout SoinsPansement:', error));
  };

  const deleteSoinsPansements = (id) => {
    axios
      .delete(`/api/SoinsPansement/${id}`)
      .then(() => {
        console.log('Soin/pansement supprimé');
        chargerSoinsPansements();
      })
      .catch((error) => console.error('Erreur suppression SoinsPansement:', error));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {/* DesinfestationHygieneComponent */}
      <div style={{ marginRight: '20px', borderRight: '1px solid black', paddingRight: '20px' }}>
        <h1>Desinfection/Hygiène</h1> 
        <form onSubmit={addDesinfestationHygiene}>
          <input placeholder='Ajouter dans Désinfestation/Hygiène' type="text" value={newDesinfestationHygiene.nom} onChange={(e) => setNewDesinfestationHygiene({ ...newDesinfestationHygiene, nom: e.target.value })} />
          <button type="submit">Ajouter</button>
        </form><br/>
        <select onChange={(e) => setSelectedDesinfestationId(e.target.value)}>
          {desinfestationHygiene.map((desinfestation, index) => (
            <option key={index} value={desinfestation.id}>{desinfestation.nom}</option>
          ))}
        </select><br/><br/>
        <button onClick={() => deleteDesinfestationHygiene(selectedDesinfestationId)}>Supprimer la catégorie sélectionné</button>
       
      </div>

      {/* DiagnosticComponent */}
      <div style={{ marginRight: '20px', borderRight: '1px solid black', paddingRight: '20px' }}>
        <h1>Diagnostics</h1>
        <form onSubmit={addDiagnostic}>
          <input placeholder='Ajouter dans Diagnostic' type="text" value={newDiagnostic.nom} onChange={(e) => setNewDiagnostic({ ...newDiagnostic, nom: e.target.value })} />
          <button type="submit">Ajouter</button>
        </form> <br/>
        <select onChange={(e) => setSelectedDiagnosticId(e.target.value)}>
          {diagnostics.map((diagnostic, index) => (
            <option key={index} value={diagnostic.id}>{diagnostic.nom}</option>
          ))}
        </select><br/><br/>
        <button onClick={() => deleteDiagnostic(selectedDiagnosticId)}>Supprimer la catégorie sélectionné</button>
       
      </div>

      {/* MobilierComponent */}
      <div style={{ marginRight: '20px', borderRight: '1px solid black', paddingRight: '20px' }}>
        <h1>Mobiliers</h1>
        <form onSubmit={addMobilier}>
          <input placeholder='Ajouter dans Mobilier' type="text" value={newMobilier.nom} onChange={(e) => setNewMobilier({ ...newMobilier, nom: e.target.value })} />
          <button type="submit">Ajouter</button>
        </form> <br/>
        <select onChange={(e) => setSelectedMobilierId(e.target.value)}>
          {mobiliers.map((mobilier, index) => (
            <option key={index} value={mobilier.id}>{mobilier.nom}</option>
          ))}
        </select><br/><br/>
        <button onClick={() => deleteMobilier(selectedMobilierId)}>Supprimer la catégorie sélectionné</button>
       
  </div>

      {/* NouveauteComponent */}
      <div style={{ marginRight: '20px', borderRight: '1px solid black', paddingRight: '20px' }}>
        <h1>Nouveautés</h1>
        <form onSubmit={addNouveaute}>
          <input placeholder='Ajouter dans Nouveauté' type="text" value={newNouveaute.nom} onChange={(e) => setNewNouveaute({ ...newNouveaute, nom: e.target.value })} />
          <button type="submit">Ajouter</button>
        </form> <br/>
        <select onChange={(e) => setSelectedNouveauteId(e.target.value)}>
          {nouveautes.map((nouveaute, index) => (
            <option key={index} value={nouveaute.id}>{nouveaute.nom}</option>
          ))}
        </select><br/><br/>
        <button onClick={() => deleteNouveaute(selectedNouveauteId)}>Supprimer la catégorie sélectionné</button>
      </div>

      {/* SoinsPansementsComponent */}
      <div style={{ marginRight: '20px', paddingRight: '20px' }}>
        <h1>Soins/Pansements</h1>
        <form onSubmit={addSoinsPansements}>
          <input placeholder='Ajouter dans Soin/Pansement' type="text" value={newSoinsPansements.nom} onChange={(e) => setNewSoinsPansements({ ...newSoinsPansements, nom: e.target.value })} />
          <button type="submit">Ajouter</button>
        </form> <br/>
        <select onChange={(e) => setSelectedSoinId(e.target.value)}>
          {soinsPansements.map((soin, index) => (
            <option key={index} value={soin.id}>{soin.nom}</option>
          ))}
        </select><br/><br/>
        <button onClick={() => deleteSoinsPansements(selectedSoinId)}>Supprimer la catégorie sélectionné</button>

      </div>
    </div>
  );
};

export default CombinedComponent;
