import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ItemTabComponent = () => {
  const [reloadComponent, setReloadComponent] = useState(false);
  const [reference, setReference] = useState("");
  const [nom, setNom] = useState("");
  const [prix, setPrix] = useState("");
  const [descriptif, setDescriptif] = useState("");
  const [promos_text, setPromos_text] = useState("");
  const [resume, setResume] = useState("");
  const [category, setCategory] = useState("");
  const [fichier_pdf, setFichier_pdf] = useState([]);
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);

  const addItemTab = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      const jsonPayload = {
        reference,
        nom,
        prix,
        descriptif,
        promos_text,
        resume,
        category,
      };

      formData.append('json', JSON.stringify(jsonPayload));

      for (let i = 0; i < images.length; i++) {
        formData.append(`image${i + 1}`, images[i]);
      }

      for (let i = 0; i < fichier_pdf.length; i++) {
        formData.append('fichier_pdf', fichier_pdf[i]);
      }

      const response = await axios.post('/api/item', formData);

      setImages([]);
      setFichier_pdf([]);

      setReference("");
      setNom("");
      setPrix("");
      setDescriptif("");
      setPromos_text("");
      setResume("");
      setCategory("");
      setReloadComponent(prevState => !prevState);
      console.log(response.data);
      alert('Nouvel item tab ajouté');
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'item tab :', error);
    }
  };

  useEffect(() => {
    axios.get('/api/Allcategory')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des catégories :', error);
      });
  }, []);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  return (
    <div key={reloadComponent}>
      <div>
        <h2>Ajouter Un Nouveau Produit</h2> <br />

        <form onSubmit={addItemTab}>
          <div>
            <div>
              <div style={{ marginBottom: '10px' }}>
                <label>Catégorie : </label>
                <select value={category} onChange={handleCategoryChange}>
                  {categories.map((category, index) => (
                    <option key={index} value={category.nom}>
                      {category.nom}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <input type="text" value={reference} onChange={(e) => setReference(e.target.value)} placeholder="Reference" />
              </div>
              <div>
                <input type="text" value={nom} onChange={(e) => setNom(e.target.value)} placeholder="Nom" />
              </div>

              <div>
                <input type="number" value={prix} onChange={(e) => setPrix(e.target.value)} placeholder="Prix" />
              </div>
              <div>
                <textarea
                  value={descriptif}
                  onChange={(e) => setDescriptif(e.target.value)}
                  placeholder="Descriptif"
                />
              </div>
              <div>
                <input type="text" value={promos_text} onChange={(e) => setPromos_text(e.target.value)} placeholder="Promos" />
              </div>
              <div>
                <input type="text" value={resume} onChange={(e) => setResume(e.target.value)} placeholder="Resume" />
              </div>
            </div>
            <div>
              <div>
                <input accept="image/*" type="file" onChange={(e) => { setImages(e.target.files) }} multiple />
              </div>
              <div>
                <input type="file" name="fichier_pdf" onChange={(e) => { setFichier_pdf(e.target.files) }} multiple />
              </div>
            </div>
          </div>
          <button type="submit">Ajouter</button>
        </form>
      </div>
    </div>
  );
};

export default ItemTabComponent;
