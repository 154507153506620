import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ListProduitComponent = () => {
    const [produits, setProduits] = useState([]);
    const [reload, setReload] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showUpdate, setShowUpdate] = useState({});
    const [searchReference, setSearchReference] = useState('');
    const [searchNom, setSearchNom] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);

    const handleClick = (image) => {
        setSelectedImage(image);
    };

    useEffect(() => {
        axios.get('/api/item')
            .then(response => {
                setProduits(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données :', error);
            });
    }, [reload]);

    const handleUpdate = async (itemId) => {
        try {
            const updatedProduct = produits.find(product => product.id === itemId);
    
            const formData = new FormData();
            formData.append('json', JSON.stringify(updatedProduct));
    
            if (updatedProduct.images && updatedProduct.images.length > 0) {
                Array.from(updatedProduct.images).forEach(image => {
                    formData.append('images', image);
                });
            }
    
            if (updatedProduct.fichier_pdf) {
                formData.append('fichier_pdf', updatedProduct.fichier_pdf);
            }
    
            const response = await axios.put(`/api/item/${itemId}`, formData);
    
            setReload(prevState => !prevState);
    
            console.log(response.data);
            alert('Produit mis à jour avec succès');
        } catch (error) {
            console.error('Erreur lors de la mise à jour du produit :', error);
        }
    };
    
    const handleDelete = (id) => {
        axios.delete(`/api/item/${id}`)
            .then(() => {
                setReload(!reload);
            })
            .catch(error => {
                console.error('Erreur lors de la suppression du produit :', error);
            });
    };

    const handleInputChange = (id, field, value) => {
        setProduits(prevState => {
            const updatedProduits = prevState.map(produit => {
                if (produit.id === id) {
                    return {
                        ...produit,
                        [field]: value
                    };
                }
                return produit;
            });
            return updatedProduits;
        });
    };

    const handleShowProduct = (product) => {
        setSelectedProduct(product);
        setShowPopup(true);
    };
    

    const toggleShowUpdate = (id) => {
        setShowUpdate(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const filteredProducts = produits.filter(produit =>
        produit.reference.toLowerCase().includes(searchReference.toLowerCase()) &&
        produit.nom.toLowerCase().includes(searchNom.toLowerCase())
    );

    const popupStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#f0f0f0',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        zIndex: '9999',
    };

    const closeStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '20px',
        color: '#333'
    };

    return (
        <div>
            <div>
                <input
                    type="text"
                    placeholder="Recherche par référence"
                    value={searchReference}
                    onChange={(e) => setSearchReference(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Recherche par nom"
                    value={searchNom}
                    onChange={(e) => setSearchNom(e.target.value)}
                />
            </div>

            {filteredProducts.map(produit => (
                <div key={produit.id} style={{ margin: '10px', padding: '20px', border: '4px solid #ccc', borderRadius: '5px', width: '450px', height: '450px', overflow: 'auto', position: 'relative' }}>
                    <div>
                        <p>Référence : {produit.reference}</p>
                        <p>Nom : {produit.nom}</p>
                        <p>Prix : {produit.prix}</p>
                        <p>Catégorie : {produit.category}</p>
                    </div>
                    <button onClick={() => handleDelete(produit.id)}>Supprimer</button><br /><br />
                    <button onClick={() => handleShowProduct(produit)}>Afficher</button><br /><br />

                    <div className="update-section">
                        <button onClick={() => toggleShowUpdate(produit.id)}>Modifier</button><br />
                        {showUpdate[produit.id] && (
                            <div style={{ marginBottom: '20px' }}>
                                <label htmlFor={`updatedReference_${produit.id}`}>Référence : </label>
                                <input
                                    id={`updatedReference_${produit.id}`}
                                    value={produit.reference}
                                    onChange={(e) => handleInputChange(produit.id, 'reference', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedNom_${produit.id}`}>Nom : </label>
                                <input
                                    id={`updatedNom_${produit.id}`}
                                    value={produit.nom}
                                    onChange={(e) => handleInputChange(produit.id, 'nom', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedPrix_${produit.id}`}>Prix : </label>
                                <input
                                    id={`updatedPrix_${produit.id}`}
                                    value={produit.prix}
                                    onChange={(e) => handleInputChange(produit.id, 'prix', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedDescriptif_${produit.id}`}>Descriptif : </label>
                                <input
                                    id={`updatedDescriptif_${produit.id}`}
                                    value={produit.descriptif}
                                    onChange={(e) => handleInputChange(produit.id, 'descriptif', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedPromos_${produit.id}`}>Promos : </label>
                                <input
                                    id={`updatedPromos_${produit.id}`}
                                    value={produit.promos_text}
                                    onChange={(e) => handleInputChange(produit.id, 'promos_text', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedResume_${produit.id}`}>Résumé : </label>
                                <input
                                    id={`updatedResume_${produit.id}`}
                                    value={produit.resume}
                                    onChange={(e) => handleInputChange(produit.id, 'resume', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedCategory_${produit.id}`}>Catégorie : </label>
                                <input
                                    id={`updatedCategory_${produit.id}`}
                                    value={produit.category}
                                    onChange={(e) => handleInputChange(produit.id, 'category', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedImages_${produit.id}`}>Images : </label>
                                <input
                                    accept="image/*"
                                    type="file"
                                    id={`updatedImages_${produit.id}`}
                                    onChange={(e) => handleInputChange(produit.id, 'images', e.target.files)}
                                    multiple
                                /><br />

                                <label htmlFor={`updatedFichierPdf_${produit.id}`}>Fichier PDF : </label>
                                <input
                                    accept="application/pdf"
                                    type="file"
                                    id={`updatedFichierPdf_${produit.id}`}
                                    onChange={(e) => handleInputChange(produit.id, 'fichier_pdf', e.target.files[0])}
                                /><br />
                                
                                <button onClick={() => handleUpdate(produit.id)}>Mettre à jour</button>
                            </div>
                        )}
                    </div>
                </div>
            ))}

{selectedProduct && showPopup && (
    <div style={popupStyle}>
        <span style={closeStyle} onClick={() => setShowPopup(false)}>&times;</span>
        <div>
            <p>Référence : {selectedProduct.reference}</p>
            <p>Nom : {selectedProduct.nom}</p>
            <p>Prix : {selectedProduct.prix}</p>
            <p>Catégorie : {selectedProduct.category}</p>
            {/* Ajouter les autres champs ici */}
            {Array.isArray(selectedProduct.images) && selectedProduct.images.length > 0 && (
                <div>
    <p>Images :</p>
    <ul>
        {selectedProduct.images.map((image, index) => (
            <li key={index}>
                <img src={`http://biotika.tofeha.tn/photo/${image}`} alt={`Image ${index}`} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} />
            </li>
        ))}
    </ul>
</div>

            )}
{selectedProduct.fichier_pdf && (
    <div>
        <p>Fichier PDF :</p>
        {Array.isArray(selectedProduct.fichier_pdf) ? (
            selectedProduct.fichier_pdf.map((pdf, index) => (
                <a key={index} href={`http://biotika.tofeha.tn/pdf/${pdf}`} target="_blank" rel="noreferrer">Voir le PDF {index + 1}</a>
            ))
        ) : (
            <a href={`http://biotika.tofeha.tn/pdf/${selectedProduct.fichier_pdf}`} target="_blank" rel="noreferrer">Voir le PDF</a>
        )}
    </div>
)}

        </div>
    </div>
)}


        </div>
    );
};

export default ListProduitComponent;
