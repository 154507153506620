import React, { useState } from 'react';
import CombinedComponent from './CategoryComponent';
import ItemTabComponent  from  './ItemTab';

const Insetcomp = () => {
    const [showCombined, setShowCombined] = useState(false);

    const toggleCombined = () => {
        setShowCombined(!showCombined);
    };

    return (
        <div>
            <button onClick={toggleCombined}>
                {showCombined ? "Masquer catégories" : "Ajouter catégories"}
            </button>
            {showCombined && <CombinedComponent />}
            <ItemTabComponent />
        </div>
    );
};

export default Insetcomp;
